import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  Form,
  FormButton,
  FormText,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@moved/ui';

import { inviteVendor, useInviteVendorPending } from '../actions/inviteVendor';

import CSS from './styles/CreateVendorModal.module.scss';

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('required'),
  lastname: Yup.string().required('required'),
  email: Yup.string().email('invalid email').required('required'),
});

export const InviteVendorModal = ({ vendor }) => {
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  const pending = useInviteVendorPending();

  const submit = (data) => {
    dispatch(inviteVendor(vendor.id, { ...data, send_invite: true }))
      .then(() => Notify.default('Invitation sent.'))
      .then(updatedVendor => Modal.close(updatedVendor))
      .catch(err => Notify.error(format.error(err)));
  };

  return (
    <Form
      name='inviteVendorForm'
      onSubmit={submit}
      validation={validationSchema}
    >
      <ModalTitle>Invite an admin for {vendor.name}</ModalTitle>
      <ModalContent className={classNames('stackVertical gap-16',CSS.content)}>
        <div className='stackHorizontal gap-24'>
          <FormText
            label='First name'
            name='firstname'
            className='width-half'
          />
          <FormText
            label='Last name'
            name='lastname'
            className='width-half'
          />
        </div>
        <FormText
          label='Email'
          name='email'
          type='email'
        />
      </ModalContent>
      <ModalActions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => Modal.close()}
          disabled={pending}
        />
        <FormButton
          text='Invite'
          color='primary'
          type='submit'
          disabled={pending}
        />
      </ModalActions>
    </Form>
  );
};
