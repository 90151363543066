import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import {
  Button,
  Form,
  FormButton,
  FormText,
  FormAddress,
  FormTextarea,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@moved/ui';

import { createVendor, useCreateVendorPending } from '../actions/createVendor';

import CSS from './styles/CreateVendorModal.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  headquarters: Yup.object().nullable().test('address','required',value => value?.google_place_id),
  commission: Yup.number().required('required'),
});

export const CreateVendorModal = () => {
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();

  const pending = useCreateVendorPending();

  const submit = ({ headquarters, ...values }) => {
    const vendorData = {
      ...values,
      vendor_addresses: [{...headquarters,name:'headquarters'}],
    };
    dispatch(createVendor(vendorData))
      .then((vendor) => Modal.close(vendor))
      .catch(err => Notify.error(format.error(err)));
  };
  return (
    <Form
      name='createVendorForm'
      onSubmit={submit}
      validation={validationSchema}
    >
      <ModalTitle>Add a new vendor</ModalTitle>
      <ModalContent className={classNames('stackVertical gap-16',CSS.content)}>
        <FormText
          label='Vendor Name'
          name='name'
        />
        <FormAddress
          label='Headquarters'
          name='headquarters'
        />
        <FormTextarea
          label='Description'
          name='default_description'
          autoResize={true}
        />
        <FormText
          label='Commission'
          name='commission'
          value='0.15'
          type='number'
        />
        <FormText
          label='Review link'
          name='review_link'
        />
      </ModalContent>
      <ModalActions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => Modal.close()}
          disabled={pending}
        />
        <FormButton
          text='Create'
          color='primary'
          type='submit'
          disabled={pending}
        />
      </ModalActions>
    </Form>
  );
};
