import React from 'react';
import Moment from 'react-moment';
import moment from 'moment'
import classNames from 'classnames';

import { AvailabilitySlot } from './AvailabilitySlot';
import { AvailabilityBlock } from './AvailabilityBlock';

import weekCSS from '../../styles/WeeklyCalendar.module.scss';

const dayStrings = {
  lastDay : 'ddd',
  sameDay : '[Today]',
  nextDay : 'ddd',
  lastWeek : 'ddd',
  nextWeek : 'ddd',
  sameElse : 'ddd'
};

export const AvailabilityDay = (props) => {

  const { date, slots, blocks, active, calendarId, modalEdit, buildingId, type } = props;

  const isToday = moment(date).isSame(moment(), 'd');

  return (
    <div className={classNames(weekCSS.day_column, {[weekCSS.active_day]: active, [weekCSS.today]: isToday, [weekCSS.no_slots]: slots.length < 1})}>
      <div className={weekCSS.day_col_border} />
      <div className={weekCSS.day_header}>
        <div className={weekCSS.header_line} />
        <div className='flex-auto'>
          <p className={weekCSS.day_of_week}>
            <Moment date={date} calendar={dayStrings} />
          </p>
          <p className={weekCSS.day_of_month}>
            <span><Moment date={date} format="D" /></span>
          </p>
        </div>
      </div>

      <div className={weekCSS.slot_holder}>
        { slots.map(slot => (
          <AvailabilitySlot
            key={`${slot.start} ${slot.end}`}
            slot={{...slot, date: date}}
            calendarId={calendarId}
            modalEdit={modalEdit}
            buildingId={buildingId}
            type={type}
          />
        ))}
        {blocks.map(block => (
          <AvailabilityBlock
            key={`block_${block.id}`}
            block={block}
            calendarId={calendarId}
            buildingId={buildingId}
            type={type}
          />
        ))}
      </div>

    </div>
  );
};
