import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { noop } from 'lodash';

import { useNotify, format, useUser } from '@moved/services';

import { RequestInfo } from './RequestInfo';
import { respondBuildingRequest, getPartnerCalendar } from '../actions';
import { useCalendarSummaries, useRespondCalendarRequestPending } from '../actions/selectors';

import { AnimatedCheckmark } from '@moved/ui';
import avaCSS from '../styles/SlotEdit.module.scss';

export const ManageRequest = ({ slot, appointment, buildingId, hide, calendarId, type, closeBtn }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { Can } = useUser();

  // state
  const [submitted, setSubmitted] = useState(false);
  // redux
  const calendars = useCalendarSummaries(buildingId);
  const calendarMap = {}
  calendars.forEach(cal => (calendarMap[cal.id] = cal.name || (cal.task_type_name === 'reserve' ? 'Elevator': 'Keys')));

  const pending = useRespondCalendarRequestPending();

  // handle the mapping of a calendar type to it's permission class
  const getRequestPermission = (action) => {
    switch(type) {
      case 'keys': return `${action}KeyPickupAppointmentRequests`;
      case 'reserve': return `${action}BuildingReservationRequests`;
      default: return null;
    }
  };

  // Handle request action
  const handleRequest = (building_calendar_id, request_id, action) => {
    dispatch(respondBuildingRequest(
      buildingId,
      building_calendar_id,
      request_id,
      action,
    ))
    .then(resp => {
      setSubmitted(true);
      setTimeout(hide, 650);
      return dispatch(getPartnerCalendar(calendarId, moment(`${slot.date}T${slot.start}`).startOf('week').format('YYYY-MM-DD')));
    })
    .catch(error => {
      const errors = format.error(error);
        return Notify.error(errors);
    });
  };

  return (
    <div className={avaCSS.slot_edit}>
      { submitted && (
        <div className={avaCSS.confirm}>
          <AnimatedCheckmark isChecked={true} fast={true} />
          <h4>Confirmed!</h4>
        </div>
      )}

      <div className={avaCSS.slot_edit_title}>
        <span>Pending request</span>
        <span className={avaCSS.date}>
          {moment(`${slot.date}T${slot.start}`).format('MMM D, YYYY | h:mm')}{moment(`${slot.date}T${slot.end}`).format(' - h:mm a')}
          {closeBtn && (<span className={avaCSS.close} onClick={e => hide()}>&times;</span>)}
        </span>
      </div>
      <div className={avaCSS.request_info}>
        <RequestInfo request={{...appointment, building_calendar_id: calendarId}} calendarMap={calendarMap} />
      </div>
      <div className={avaCSS.slot_edit_control}>
        <Can I={getRequestPermission('Reject')}>
          <label
            role="button"
            className={'btn-gray flex-auto mr-10'}
            disabled={pending}
            onClick={pending ? noop : e => handleRequest(calendarId,appointment.id,'reject')}
          >
            Decline
          </label>
        </Can>
        <Can I={getRequestPermission('Approve')}>
          <label
            tabIndex="0"
            role="button"
            className={'btn-primary flex-auto ' + (pending  ? ' loading' : '')}
            disabled={pending}
            onClick={pending ? noop : e => handleRequest(calendarId,appointment.id,'approve')}
          >
            Accept
          </label>
        </Can>
      </div>
    </div>
  );
}
