import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useDrawer, useNotify, format } from '@moved/services';

import { getVendor } from '../actions/getVendor';
import { useVendorSearchContext } from './VendorSearch';
import { VendorDetailsDrawer } from './VendorDetailsDrawer';

export const VendorDetails = () => {
  const Drawer = useDrawer();
  const Notify = useNotify();
  const history = useHistory();
  const dispatch = useDispatch();

  const { vendorId } = useParams();
  const { refresh } = useVendorSearchContext();

   useEffect(() => {
    dispatch(getVendor(parseInt(vendorId)))
      .then(() => {
        Drawer.open(
          <VendorDetailsDrawer vendorId={vendorId} refreshSearch={refresh} />,
          {
            onClose: () => history.push({
              pathname: `/cx/vendors`,
              search: history.location.search,
            })
          }
        );
      })
      .catch(err => {
        Notify.error(format.error(err));
        history.push({
          pathname: `/cx/vendors`,
          search: history.location.search,
        });
      });
    return () => Drawer.close();
  },[vendorId]); // eslint-disable-line

  return null;
};
