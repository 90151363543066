import React from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import { get } from 'lodash';

import { useModal, useUser } from '@moved/services';
import { PopOver, Icon, ConfirmationModal } from '@moved/ui';
import { MoveStepTypeIcon } from '@moved/product';

import { getPartnerCalendar, cancelReservation } from '../../actions/';
import { ManageRequest } from '../ManageRequest';
import { RescheduleAppointment } from '../RescheduleAppointment';
import { SlotEdit } from '../SlotEdit';
import CSS from '../../styles/WeeklyCalendar.module.scss';

const ConditionalPopOver = ({ className, appointment, type, calendarId, slot, buildingId, children }) => {
  const { hasAbilities } = useUser();
  return ((type === 'reserve' && hasAbilities('RescheduleBuildingReservations'))
    || (type === 'move-out-inspection' && hasAbilities('RescheduleBuildingReservations'))
    || (type === 'keys' && hasAbilities('RescheduleKeyPickupAppointments')))
    ? (
      <PopOver
        placement="right"
        trigger="click"
        interactive={true}
        mountToBody={true}
        className={className}
        stopPropagation={true}
        tooltip={(hide) => <RescheduleAppointment appointment={appointment} type={type} calendarId={calendarId} slot={slot} hide={hide} buildingId={buildingId} />}
        hideArrow={true}
      >
        {children}
      </PopOver>
    ): (
      <div className={className}>{children}</div>
    );
}

const SlotContent = ({ slot, type, calendarId, buildingId, modalEdit }) => {
  const dispatch = useDispatch();
  const Modal = useModal();
  const { Can } = useUser();

  const numJobs = isEmpty(slot.appointments) ? 0 : Object.keys(slot.appointments).length;

  const showSlotModal = () => {
    return Modal.open(<div style={{paddingTop:'40px'}}><SlotEdit calendarId={calendarId} type={type} slot={slot} jobs={numJobs} /></div>);
  };

  const resCancel = appointment => dispatch(cancelReservation(
    type === 'keys' ? 'key-pickup-appointments' : 'building-reservations',
    appointment.id,
    calendarId,
  )).then(resp => dispatch(getPartnerCalendar(calendarId, moment(`${slot.date}T${slot.start}`).startOf('week').format('YYYY-MM-DD'))));

  const cancelAppointment = appointment => Modal.open(
    <ConfirmationModal
      title={'Cancel appointment'}
      copy={`Are you sure wish to cancel ${appointment.firstname} ${appointment.lastname}'s appointment on ${moment(appointment.start_time).format('MMMM DD, YYYY')}?`}
      confirmText={'Proceed with cancellation'}
      cancelText={'Never mind'}
      onConfirm={e => resCancel(appointment)}
    />,
    { sondheim: true },
  );

  return (
      <>

      <div className={CSS.slot_line} />

      <div className={CSS.slot_actions}>
        <div className={CSS.slot_top}>
          {slot.appointments.length > 0 && (
            <div className={CSS.slot_appointments}>
              {slot.appointments.map(appointment => (
                <div className={CSS.slot_appointment} key={appointment.id}>
                  <ConditionalPopOver
                    className={CSS.trigger}
                    key={`${appointment.id}_${appointment.type}`}
                    {...{ appointment, type, calendarId, slot, buildingId }}
                  >
                    <span onClick={e => e.stopPropagation()} className={CSS.slot_tenant}>{appointment.lastname}</span>
                    <span className={CSS.slot_unit}>
                      <MoveStepTypeIcon type={get(appointment,'move_step_type.label')} size='16px' />
                      {appointment.unit}
                    </span>
                  </ConditionalPopOver>
                  <Can I={(type === 'reserve')
                    ? 'CancelBuildingReservations'
                    : (((type === 'keys'))
                      ? 'CancelKeyPickupAppointments'
                      : null
                    )
                  }>
                    <span className={CSS.cancel_appointment} onClick={e => {e.stopPropagation(); return cancelAppointment(appointment);}}>
                      <Icon library={'general'} symbol={'Trash'} size={'16px'}  />
                    </span>
                  </Can>
                </div>
              ))}
            </div>
          )}
          {slot.requests.length > 0 && (
            <div className={CSS.slot_requests}>
              {slot.requests.map(appointment => (
                <PopOver
                  placement="right"
                  trigger="click"
                  interactive={false}
                  mountToBody={true}
                  className={CSS.slot_request}
                  key={`${appointment.id}_${appointment.type}`}
                  tooltip={(hide) => <ManageRequest appointment={appointment} type={type} calendarId={calendarId} slot={slot} hide={hide} buildingId={buildingId} />}
                  hideArrow={true}
                >
                  <span className={CSS.slot_tenant}>{appointment.lastname}</span>
                  <span className={CSS.slot_pending}>
                    <Icon symbol={'Time-schedule'} library={'code'} className={classNames(CSS.direction_icon, CSS.out)} size={'16px'} />
                  </span>
                  <span className={CSS.slot_new}>NEW</span>
                </PopOver>
              ))}
            </div>
          )}
          <Can I={(type === 'reserve')
            ? 'CreateBuildingReservations'
            : (((type === 'keys'))
              ? 'CreateKeyPickupAppointments'
              : null
            )
          }>
            {slot.is_available && (
              modalEdit ? (slot.appointments.length < slot.max_appointments) && (
                <div className={CSS.slot_adds}>
                  <div className={CSS.slot_add} onClick={showSlotModal}>
                    <div className={CSS.slot_btn}>+</div>
                  </div>
                </div>
              ) : (
                <PopOver
                  placement="right"
                  trigger="click"
                  interactive={false}
                  mountToBody={true}
                  className={CSS.slot_adds}
                  tooltip={hide => <SlotEdit type={type} calendarId={calendarId} slot={slot} jobs={numJobs} hide={hide} />}
                  hideArrow={true}
                >
                  {
                    (slot.appointments.length < slot.max_appointments) && (
                      <div className={CSS.slot_add}>
                        <div className={CSS.slot_btn}>
                          <Icon symbol='Plus-small' library='navigation' size='20px' />
                        </div>
                      </div>
                    )
                  }
                </PopOver>
              )
            )}
          </Can>
        </div>

        <div className={CSS.slot_info}>
          {slot.is_available && (<span>{`${slot.appointments.length}/${slot.max_appointments}`}</span>)}
          {!slot.is_available && slot.appointments.length <= 0 && (<span>Unavailable</span>)}
        </div>
      </div>

    </>
  );
};

export const AvailabilitySlot = ({ slot, type, calendarId, buildingId, modalEdit }) => {

  if(!slot.max_appointments) slot.max_appointments = 1;

  // Times for placement (convert to UTC to avoid DST quirkiness)
  const midnight = moment.utc(`${slot.date} 00:00:00`);
  const slotStart = moment.utc(`${slot.date} ${slot.start}`);
  const slotEnd = moment.utc(`${slot.date} ${slot.end}`);

  const slotClasses = classNames(
    CSS.slot,
    {
      [CSS.slot_with_apps]: slot.appointments.length > 0 || slot.requests.length > 0,
      [CSS.slot_full]: slot.appointments.length >= slot.max_appointments,
      [CSS.slot_small]: slotEnd.diff(slotStart,'minutes') < 31,
      [CSS.slot_unavailable]: !slot.is_available && slot.appointments.length <= 0,
    },
  );

  return (
    <div className={slotClasses} style={{ top: `${slotStart.diff(midnight,'minutes')-1}px`, height: `${slotEnd.diff(slotStart,'minutes')+1}px`,}}>
      <div className={CSS.slot_content}>
        <SlotContent calendarId={calendarId} type={type} slot={slot} buildingId={buildingId} modalEdit={modalEdit} />
      </div>
    </div>
  );
}
