import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

import { useModal, useNotify, useUser, useQuery } from '@moved/services';
import { LoaderOverlay, Button } from '@moved/ui'
import { SearchScreen } from '@moved/product';

import { getBuildings } from '../../../activity/moves/actions'
import { useBuildings, useBuildingsPending } from '../../../activity/moves/actions/selectors';

import { getClientSearch } from '../../clients/actions';
import { useClientSearch, useClientSearchPending } from '../../clients/actions/selectors';

import { getAdminSearch } from '../actions';
import { useAdminSearch, useAdminSearchPending, } from '../actions/selectors';

import { CreateAdminModal, AdminResult } from './'
import columnsCSS from './styles/columns.module.scss';

export const AdminSearchWrapper = () => {
  const { Can } = useUser();
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  let buildingParams = useQuery('building_ids');

  // STATE
  const [activeBuildings, setActiveBuildings] = useState([]);

  // REDUX
  const buildings = useBuildings();
  const buildingsPending = useBuildingsPending();
  const clients = useClientSearch();
  const clientsPending = useClientSearchPending();
  const pending = buildingsPending || clientsPending;

  // Fetch partner buildings and task titles on mount
  useEffect(() => {
    dispatch(getBuildings({limit: 500}))
      .catch(error => Notify.error());

    dispatch(getClientSearch({limit: 500}))
      .catch(error => Notify.error());
  // eslint-disable-next-line
  },[]);

  useEffect(() => {
      if(buildingParams) {
        dispatch(getBuildings({building_ids: buildingParams}))
          .then(resp => {
            setActiveBuildings(resp);
          });
      } else {
        setActiveBuildings([]);
      }
  // eslint-disable-next-line
  },[buildingParams]);

  const filters = [
    {
      param: 'partner_ids',
      multi: true,
      intValues: true,
      filter: {
        label: 'Client',
        type: 'multiSelect',
        list: get(clients,'activeSet',[]),
        props: { title: "Client", },
      },
    },
    {
      param: 'building_ids',
      multi: true,
      intValues: true,
      filter: {
        label: 'Property',
        type: 'typeAhead',
        list: buildings,
        props: {
          title: "Property name",
          activeItems: activeBuildings,
          searchAction: (params, cancelToken) => dispatch(getBuildings(params, cancelToken)),
        },
      },
    },
  ];

  const sortables = [
    { value: 'icon', disabled: true },
    { value:'name', label: 'Admin name'},
    { value:'num_buildings', label: 'Properties'},
    { value:'partner_name', label: 'Client'},
    { value:'invite_status', label: 'Invite status', disabled: true },
  ];

  return (
    <>
      <Helmet>
        <title>Admin Abode Moves : Moved</title>
      </Helmet>

      { pending && (<LoaderOverlay />) }

      <SearchScreen
        title={'Admins'}
        getSearchItems={getAdminSearch}
        useSearchItems={useAdminSearch}
        useSearchItemsPending={useAdminSearchPending}
        filters={filters}
        sortables={sortables}
        sortableCSS={columnsCSS}
        Result={AdminResult}
        actions={(
          <Can I={'CreateAdmins'}>
            <Button
              text='Create admin'
              icon={{ library: 'navigation', symbol: 'Plus' }}
              size='small'
              color='secondary'
              onClick={() => Modal.open(<CreateAdminModal />)}
            />
          </Can>
        )}
      />
    </>
  )
};
