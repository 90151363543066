import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { get, find } from 'lodash';
import moment from 'moment'

import { LoaderOverlay, Icon, FilterSearch, PopOver, MonthlyCalendar, SlideToggle } from '@moved/ui';
import { useModal, useNotify, format, useUser } from '@moved/services';

import { BlockTime } from './BlockTime';
import { NotificationsMenu } from './NotificationsMenu';
import { useClient } from '../../../../common/actions/selectors';
import { usePartnerCalendar, usePartnerCalendarPending, useCalendarSummaries, useCalendarSummariesPending } from '../actions/selectors';
import { getPartnerCalendar, getBuildingCalendarSummaries } from '../actions/';
import { getBuildings } from '../../moves/actions';

import CSS from '../styles/WeeklyCalendar.module.scss';

import { AvailabilityWeek } from './WeeklyCalendar/AvailabilityWeek';

const _buildingNames = (selected, buildings) => find(buildings, building => building.id === selected[0]).name;

const _getMonths = date => {
  const start = date.clone().startOf('week').format('MMMM');
  const end = date.clone().endOf('week').format('MMMM');
  if(start === end) return `${start} ${date.format('YYYY')}`;
  else return `${start} - ${end} ${date.format('YYYY')}`
};

const _showBlockModal = (Modal,id,type) => Modal.open(<BlockTime {...{type, id}} />);

const _backupCalName = type => {
  switch(type) {
    case 'reserve':
      return 'Elevator';
    case 'keys':
      return 'Keys';
    default:
      return 'Calendar';
  };
}

export const Calendar = (props) => {
  const dispatch = useDispatch();
  const Modal = useModal();
  const Notify = useNotify();
  const { Can, getDomainId } = useUser();

  // BUILDINGS REDUX
  const buildings = useSelector(state => state.buildings);

  // STATE
  const [currentCal, setCurrentCal] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buildingFilter, setBuildingFilter] = useState(buildings && buildings.length > 0 ? [buildings[0].id] : false);
  const [activeDate, setActiveDate] = useState(moment());

  // PARTNER + CALENDAR REDUX
  const partner = useClient(getDomainId('client'));

  const viewCal = usePartnerCalendar(currentCal);
  const summaries = useCalendarSummaries(buildingFilter[0]);
  const filterList = summaries.map(summary => {
    return {
      label: summary.name || _backupCalName(summary.task_type_name),
      value: summary.id,
    };
  });

  const currentSummary = find(summaries, summary => summary.id === currentCal);

  const getCalendarPending = usePartnerCalendarPending();
  const summariesPending = useCalendarSummariesPending();

  useEffect(() => {
    if(summaries.length > 0) setCurrentCal(filterList[0].value);
  },[summaries]); // eslint-disable-line

  useEffect(() => {
    if (buildingFilter && buildingFilter[0]) dispatch(getBuildingCalendarSummaries(buildingFilter[0]));
  },[buildingFilter[0]]); // eslint-disable-line

  useEffect(() => {
    if (partner) dispatch(getBuildings({limit: 500}));
  },[partner]); // eslint-disable-line

  useEffect(() => {
    setBuildingFilter(buildings && buildings.length > 0 ? [buildings[0].id] : false);
  },[buildings]); // eslint-disable-line

  useEffect(() => {

    setLoading(true);

    if(buildingFilter && currentCal) {
      dispatch(getPartnerCalendar(currentCal, activeDate.clone().startOf('week').format('YYYY-MM-DD')))
        .then(() => setError(false))
        .catch(err => {
          if(get(err,'response.status') === 404) {
            return setError(true);
          } else {
            return Notify.error(format.error(error));
          }
        })
        .finally(() => setLoading(false));
    }
  },[buildingFilter, activeDate, currentCal]); // eslint-disable-line

  // --- Set up week ---
  const startOfWeek = activeDate.clone().startOf('week');
  const endOfWeek = activeDate.clone().endOf('week');

  const week = [];
  let day = startOfWeek;

  const availability = get(viewCal,'availability') || {};
  const appointments = get(viewCal,'appointments') ? [...get(viewCal,'appointments')] : [];

  if(!error) {
    // Render spinner until necessary data is loaded
    if(!viewCal || !availability || !buildings) return (
      <>
        <Helmet>
          <title>{`Availability Calendar ${buildingFilter.length > 0 ? _buildingNames(buildingFilter, buildings) : partner.name} : Moved`}</title>
        </Helmet>
        <LoaderOverlay />
      </>
    );

    while(day <= endOfWeek) {
      const date = day.format('YYYY-MM-DD');
      const isAppointmentInSlot = (appointment, slot) => moment(appointment.start_time).isBetween(`${date}T${slot.start}Z`,`${date}T${slot.start}Z`, undefined, '[]');
      const weekday = {
        date,
        blocks: appointments.filter(appt => appt.type === 'blocked' && moment(appt.start_time).utc().isSame(date, 'day')),
        slots: get(availability, `${date}.timeslots`, []).map(slot => ({
          ...slot,
          appointments: appointments.filter(appointment => appointment.type === 'appointment' && isAppointmentInSlot(appointment,slot)),
          requests: appointments.filter(appointment => appointment.type === 'request' && isAppointmentInSlot(appointment,slot)),
        })),
      };
      week.push(weekday);
      day = day.clone().add(1, 'd');
    }
  }

  // Primary Render
  return (
    <>
      <Helmet>
        <title>{`${buildingFilter.length > 0 ? _buildingNames(buildingFilter, buildings) : partner.name} Calendar : Moved`}</title>
      </Helmet>

      { (loading || getCalendarPending || summariesPending) && (<LoaderOverlay />) }

      <div className={CSS.content}>

        <div className={CSS.header}>
          <div className={CSS.title}>
            <FilterSearch list={buildings} active={buildingFilter} title="Select building" popOverClass={CSS.filter} onSelect={setBuildingFilter} single={true}>
              <div className={CSS.building_trigger}>
                <span>{ buildingFilter.length > 0 ? _buildingNames(buildingFilter, buildings) : 'Please select a building' }</span>
                <Icon symbol='Chevron-down' library='navigation' size='24px' />
              </div>
            </FilterSearch>
          </div>
          <div className={CSS.settings}>
            <NotificationsMenu buildingId={buildingFilter[0]} />
            <Can I="CreateBuildingAppointments">
            <div className={CSS.btn_add} onClick={e => _showBlockModal(Modal,currentCal)}>
              <span>Block time</span>
              <Icon symbol='Plus' library='code' size='16px' />
            </div>
            </Can>
          </div>
        </div>

        <div className={CSS.bar}>
          <div className={CSS.bar_left}>


            <div className={CSS.cal_nav}>
              <div className={CSS.today_btn} onClick={e => setActiveDate(moment())}>Today</div>
              <div className={CSS.arrow_btn} onClick={e => setActiveDate(activeDate.clone().subtract(1,'weeks'))} >
                <Icon symbol='Chevron-left' library='navigation' size='24px' />
              </div>
              <div className={CSS.arrow_btn} onClick={e => setActiveDate(activeDate.clone().add(1,'weeks'))} >
                <Icon symbol='Chevron-right' library='navigation' size='24px' />
              </div>
            </div>

            <PopOver
              placement="bottom"
              trigger="click"
              interactive={false}
              className={CSS.monthly}
              tooltip={
                <div className={CSS.monthly_cal}>
                  <MonthlyCalendar
                    selected={activeDate}
                    onSelect={setActiveDate}
                    moment={moment(activeDate)}
                    highlightWeek={true}
                    highlightDay={false}
                    hoverWeek={true}
                  />
                </div>
              }
              hideArrow={true}
            >
              {_getMonths(activeDate)}
            </PopOver>
          </div>

          <div className={CSS.bar_right}>
            { summaries.length <= 4 && (
              <SlideToggle
                active={currentCal}
                options={filterList}
                callback={setCurrentCal}
                rectangular={true}
              />
            )}
          </div>
        </div>

        {summaries.length > 4 && (
          <div className={CSS.bar_long_toggle}>
            <SlideToggle
              active={currentCal}
              options={filterList}
              callback={setCurrentCal}
              rectangular={true}
            />
          </div>
        )}

        <div className={CSS.calendar}>
          {(error && !loading) ? (
            <div className={CSS.no_calendar}>
              <Icon symbol='Warning-2' library='code' size='48px' color='orange' />
              <h4>There is currently no calendar configured for { buildingFilter.length > 0 ? _buildingNames(buildingFilter, buildings) : 'this building' }.</h4>
            </div>
          ) : (
            <AvailabilityWeek
              calendarId={currentCal}
              selected={activeDate}
              week={week}
              getCalendarPending={getCalendarPending}
              buildingId={buildingFilter[0]}
              type={get(currentSummary,'task_type_name')}
            />
          )}

        </div>

      </div>
    </>
  );
};
