import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { get } from 'lodash';

import { useNotify, format } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { getMoveDetails } from '../actions/';
import { useMoveDetails, useMoveDetailsPending } from '../actions/selectors';
import { MoveDetails } from './MoveDetails';
import { LegacyMoveDetails } from './LegacyMoveDetails';

import CSS from '../styles/MoveDetails.module.scss';


export const MoveDetailsWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const Notify = useNotify();
  const { moveId } = useParams();

  const move = useMoveDetails(moveId);
  const pending = useMoveDetailsPending();

  const isMoveLegacy = get(move,'app') === 'direct';

  useEffect(() => {
    dispatch(getMoveDetails(moveId))
      .catch((error) => Notify.error(format.error(error)));
  },[moveId]); // eslint-disable-line

  if(!move) return (
    <>
      <Helmet>
        <title>{`Moves`}</title>
      </Helmet>
      <LoaderOverlay />
    </>
  );

  return (
    <>
      <Helmet>
        <title>{`${get(move,'user.firstname')} ${get(move,'user.lastname')} Move : Moved`}</title>
      </Helmet>

      { pending && (<LoaderOverlay />) }

      <div className={classNames(CSS.content)}>

        { isMoveLegacy ? (
          <LegacyMoveDetails move={move} />
        ) : (
          <>
            <MoveDetails move={move} />
            { children }
          </>
        )}
      </div>
    </>
  );
};
