import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';

import { useModal, useNotify, format } from '@moved/services';
import { CardSetupForm, StyledForm, FieldSlideToggle, Collapsible } from '@moved/ui';

import { resolvePaymentIssue, retryPaymentAuthorization, getOrder } from '../../actions';

import CSS from './AddPaymentModal.module.scss';

const statusToActionMap = {
  payment_issue: resolvePaymentIssue,
  pre_authorization_failed: retryPaymentAuthorization,
};

export const ResolvePaymentIssueModal = ({ order={} }) => {
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();
  const [pending, setPending] = useState();
  const [retry, setRetry] = useState(true);

  const resolutionAction = statusToActionMap[get(order,'payment_status')];

  const submitResolve = ({ setupIntent={} }) => {
    dispatch(resolutionAction(order.id, { stripe_payment_method_id: setupIntent.payment_method }))
      .then(() => {
        Notify.default(`Payment successfully resolved!`);
        Modal.close();
      })
      .catch(error => {
        // refresh the order to see the new payment attempt
        dispatch(getOrder(order.id)).then(() => {
          setPending(false);
          Notify.error(format.error(error));
        });
      });
  };

  const handleStripeError = (error) => {
    setPending(false);
    Notify.error(
      format.error(error,false,format.stripeError), // map stripe errors
      { autoHideDuration: 6000 }, // slightly longer than normal display times
    );
  };

  const toggleField = {
    name: 'retry',
    options: [
      {
        label: 'Retry with card on file',
        value: true,
      },
      {
        label: 'Enter new card details',
        value: false,
      }
    ],
    toggleProps: {
      full: true,
      rectangular: true,
    }
  };

  const handleToggle = ({retry}) => setRetry(retry);

  // retry handler
  const handleSubmit = () => {
    if(pending || !retry) return;
    setPending(true);
    submitResolve({});
  }

  return (<>
    <h3 className={CSS.title}>Resolve payment issue</h3>
    <div className={CSS.previous_message}>
      Failed payment with {format.capitalize(get(order,'default_payment_method.card_brand'))} ending in {get(order,'default_payment_method.card_last4')}.
    </div>
    <StyledForm id='test' formStyle='underline' className={CSS.form} onChange={handleToggle} initialValues={{retry}}>
      {(formik) => (
        <FieldSlideToggle form={formik} input={toggleField}/>
      )}
    </StyledForm>
    <Collapsible open={!retry}>
      <CardSetupForm
        onSubmit={() => setPending(true)}
        onSuccess={submitResolve}
        onError={handleStripeError}
        className={CSS.form}
        submitLabel={false}
        formId='stripe-card-form'
      />
    </Collapsible>
    <div className={CSS.actions}>
      <button
        className={classNames(CSS.action,'btn-gray')}
        onClick={Modal.close}
      >
        Cancel
      </button>
      { retry ? (
        <button
          className={classNames(CSS.action,'btn-primary',{loading:pending})}
          disabled={pending}
          onClick={handleSubmit}
        >
          Retry
        </button>
      ) : (
        <button
          className={classNames(CSS.action,'btn-primary',{loading:pending})}
          disabled={pending}
          type='submit'
          form='stripe-card-form'
        >
          Update & Retry
        </button>
      )}
    </div>
  </>);

}
